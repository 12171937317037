
import {Routes, Route} from "react-router-dom";
import React from 'react';
import {AppContextProvider} from "./context/AppContext";
import {LandingPage} from "./pages/LandingPage";
function App() {

  return (
 <AppContextProvider>
     <Routes>
         <Route path="/" element={<LandingPage/>}>

         </Route>
         <Route path="*" element={<NoFound/>} />
     </Routes>
 </AppContextProvider>
  );

}


export default App;

function NoFound(){
    return (
        <div>
            <h2>404 Not Found</h2>
        </div>
    );
}

